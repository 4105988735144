<template>
  <v-container>

    <v-row>
      <v-col cols="12">

        <v-row class="mb-3">
          <v-col>
            <v-btn to="/admin/news" color="secondary" small>
              <v-icon class="mr-2">mdi-arrow-left</v-icon>
              Volver
            </v-btn>
          </v-col>
        </v-row>

        <v-card>
          <v-card-title class="pb-0">
            <v-container>

              <v-row>
                <v-col class="mb-4 py-0">
                  <p class="text-h5 mb-0">
                    <v-avatar color="blue" size="38"><v-icon dark>mdi-newspaper</v-icon></v-avatar>
                    Editar Noticia
                  </p>
                </v-col>
              </v-row>

            </v-container>
          </v-card-title>
          
          <v-card-text v-if="!isLoading">

            <v-container>

              <!-- General info inputs -->
              <v-row>
                <v-col>
                  <v-text-field 
                    v-model="editArticle.title"
                    label="Nombre de la noticia*" 
                    outlined dense
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    v-model="editArticle.dateDay"
                    type="date"
                    label="Fecha*"
                    outlined dense
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                      v-model="editArticle.dateHour"
                      type="time"
                      label="Fecha*"
                      outlined dense
                  />
                </v-col>
                <v-col cols="4">
                  <v-file-input
                    v-model="editArticle.articleImg"
                    accept="image/*"
                    label="Imagen de portada*"
                    outlined dense
                  />
                </v-col>
              </v-row>

              <v-divider class="mb-5"></v-divider>

              <!-- Markdown Editor -->
              <v-row>
                <mavon-editor v-model="editArticle.markdown" language="en" />
              </v-row>

              <v-divider class="my-6"></v-divider>

              <!-- Submit -->
              <v-row>
                <v-col cols="6">
                  <v-checkbox
                    v-model="editArticle.isVisible"
                    label="¿Es visible para el público?"
                  />
                </v-col>
                <v-col cols="6" class="text-right">
                  <v-btn @click="modifyArticle()" color="success">
                    <v-icon class="mr-2">mdi-floppy</v-icon>
                    Guardar
                  </v-btn>
                </v-col>
              </v-row>

            </v-container>
          </v-card-text>

          <LoadingIndicator v-else />
        </v-card>

      </v-col>
    </v-row>

    <!-- snack notification -->
    <v-snackbar
      v-model="isSnackbar"
      :color="snackcolor"
      :timeout="2000"
      top
    >
      {{ snacktext }}
    </v-snackbar>

  </v-container>
</template>

<script>
import { mavonEditor } from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
import LoadingIndicator from "@/components/LoadingIndicator";
import {getHHMM, getYYYYMMDD} from "@/libs/dateHelpers";
import {convertURLtoFile} from "@/libs/fileHelpers";

export default {
  name: `AdminCreateArticle`,
  components: {
    mavonEditor,
    LoadingIndicator
  },
  data: () => ({
    //state
    isLoading: true,
    isSaving: false,
    isSnackbar: false,
    snackcolor: 'success',
    snacktext: '',
    //data
    headers: [
      { text: 'Nombre', value: 'name' },
      { text: 'Profesor', value: 'professorName' },
      { text: 'Email del Profesor', value: 'professorEmail' },
      { text: 'Cupos', value: 'slots' },
      { text: 'Acciones', value: 'actions' },
    ],
    //form
    editArticle: {
      _id: null,
      title: null,
      description: null,
      articleImg: null,
      markdown: null,
      dateDay: null,
      dateHour: null,
      isVisible: true,
    }
  }),
  methods: {
    async loadArticle() {
      try {
        this.isLoading = true

        if(!this.$route.params.slug) {
          this.snackcolor = 'error'
          this.snacktext = 'No se ha cargado la noticia, el identificador es invalido'
          this.isSnackbar = true
          return
        }

        const resp = await this.axios.get(`${process.env.VUE_APP_APIURL}/article/slug/${this.$route.params.slug}`)

        //parse data as YYYYMMDD
        const dateWithoutTZ = new Date(resp.data.data.date)
        const dateDay = getYYYYMMDD(dateWithoutTZ)
        const dateHour = getHHMM(dateWithoutTZ)

        //parse url as file
        const parsedArticleImg = (resp.data.data.imgURL) ?  await convertURLtoFile(`${process.env.VUE_APP_APIURL}/uploads/${resp.data.data.imgURL}`) : null

        Object.assign(
            this.editArticle,
            resp.data.data,
            {
              dateDay: dateDay,
              dateHour: dateHour,
              articleImg: parsedArticleImg
            },
        )

      } catch (e) {
        console.error(e)
        this.snackcolor = 'error'
        this.snacktext = 'Hubo un problema al cargar la noticia'
        this.isSnackbar = true
      } finally {
        this.isLoading = false
      }
    },
    async modifyArticle() {
      try {

        if(!this.editArticle.title || !this.editArticle.articleImg || !this.editArticle.dateDay || !this.editArticle.dateHour || !this.editArticle.markdown) return this.invalidFormNotification()
        
        this.isSaving = true

        let fd = new FormData()
        fd.append('title', this.editArticle.title)
        fd.append('date', `${this.editArticle.dateDay}T${this.editArticle.dateHour}`)
        fd.append('markdown', this.editArticle.markdown)
        fd.append('isVisible', this.editArticle.isVisible)
        fd.append('articleImg', this.editArticle.articleImg)

        await this.axios.put(`${process.env.VUE_APP_APIURL}/article/id/${this.editArticle._id}`, fd, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })

        this.snackcolor = 'success'
        this.snacktext = 'Noticia editada'
        this.isSnackbar = true

        this.$router.push('/admin/news')

      } catch (e) {
        console.error(e)
        this.snackcolor = 'error'
        this.snacktext = 'Hubo un problema al editar la noticia'
        this.isSnackbar = true
      } finally {
        this.isSaving = false
      }
    },
    invalidFormNotification() {
      this.snackcolor = 'error'
      this.snacktext = 'Por favor complete los campos requeridos'
      this.isSnackbar = true
    },
  },
  mounted() {
    this.loadArticle()
  }
}
</script>